import React from 'react';
import {Container, Segment,} from 'semantic-ui-react';
import {graphql, StaticQuery} from "gatsby";
import styles from "./contact.module.css";

const ContactDescription = () => (
    <StaticQuery query={contactDescriptionQuery}
                 render={data => (
                     <Segment>
                         <Container className={styles.contactDescriptionBody}
                             dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}>
                         </Container>
                     </Segment>
                 )}
    />
);

export default ContactDescription;

const contactDescriptionQuery = graphql`
    query contactDescriptionQuery {
      markdownRemark(frontmatter: {type: {eq: "general"}, title: {eq: "Allgemeines"}}) {
        html
      }
    }
`;