import React from 'react';
import {Card, Container} from 'semantic-ui-react';
import styles from "./aboutUs.module.css";
import {graphql, StaticQuery} from "gatsby";
import Img from "../utils/Img";

const Office = () => (
    <div className={styles.main}>
        <StaticQuery query={officeItemsQuery}
                     render={
                         data => {
                             const OfficeItems = data.allMarkdownRemark.edges.map(i =>
                                 <>
                                     <Card className={styles.officeCard}>
                                         {
                                             !!i.node.frontmatter.image &&
                                             <Img fluid={i.node.frontmatter.image.childImageSharp.fluid}/>
                                         }
                                         <Card.Content>
                                             <Card.Header>{i.node.frontmatter.title}</Card.Header>
                                             <Card.Meta>
                                                 {i.node.frontmatter.summary}
                                             </Card.Meta>
                                             <Card.Description>
                                                 <Container style={{marginBottom: '1rem'}} className={styles.content}
                                                            dangerouslySetInnerHTML={{__html: i.node.html}}>
                                                 </Container>
                                             </Card.Description>
                                         </Card.Content>
                                     </Card>
                                 </>
                             );
                             return (<Card.Group centered>{OfficeItems}</Card.Group>)
                         }
                     }
        />

    </div>
);

export default Office;

const officeItemsQuery = graphql`
query officeItemsQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {
            type: { eq: "office" }
         }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
            html
            frontmatter {
                title
                summary
                image {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
            }
        }
      }
    }
}
`;