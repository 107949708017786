import React from "react";
import {Link, navigate} from "@reach/router";
import {Button, Checkbox, Form, Input, TextArea} from "formik-semantic-ui";
import {Icon} from "semantic-ui-react";
import styles from "./friendform.module.css";

function encode(data) {
    const formData = new FormData();

    for (const key of Object.keys(data)) {
        formData.append(key, data[key]);
    }

    return formData;
}

class ContactReqForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vorname: '',
            nachname: '',
            email: '',
            nachricht: '',
            datenschutz: false,
        };
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };


    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/?no-cache=1", {
            method: "POST",
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
            .then(() => navigate(form.getAttribute("action")))
            .catch(error => console.log(error));
    };

    _handleSubmit2 = (values, formikApi) => {
        // console.log(values);
        let errors = false;
        setTimeout(() => {
                Object.keys(values).forEach(key => {
                    if (!values.emailVal) {
                        errors = true;
                        formikApi.setFieldError("emailVal", "Pflichtfeld");
                    }
                    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailVal)) {
                        errors = true;
                        formikApi.setFieldError("emailVal", "Ungültige E-Mail");
                    }
                    if (!values.firstNameVal) {
                        errors = true;
                        formikApi.setFieldError("firstNameVal", "Pflichtfeld");
                    }
                    if (!values.lastNameVal) {
                        errors = true;
                        formikApi.setFieldError("lastNameVal", "Pflichtfeld");
                    }
                    if (!values.privacyVal) {
                        errors = true;
                        formikApi.setFieldError("privacyVal", "Pflichtfeld");
                    }
                    if (!values.messageVal) {
                        errors = true;
                        formikApi.setFieldError("messageVal", "Pflichtfeld");
                    }

                    if (errors) {
                        formikApi.setSubmitting(false);
                    }
                });
                if (!errors) {
                    this.setState({vorname: values.firstNameVal});
                    this.setState({nachname: values.lastNameVal});
                    this.setState({email: values.emailVal});
                    this.setState({nachricht: values.messageVal});
                    this.setState({datenschutz: values.privacyVal});
                    formikApi.setSubmitting(true);
                    document.getElementById('contact-submit').click();
                }

            },
            150);

    };

    render() {
        const {vorname, nachname, nachricht, datenschutz, email} = this.state;
        // console.log(file);
        return (
            <div className={styles.main}>
                <form
                    name="Kontaktanfrage2"
                    method="post"
                    action="/freunde/danke/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                    hidden
                >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="Kontaktanfrage2" />
                    <p hidden>
                        <label>
                            Don’t fill this out:{" "}
                            <input name="bot-field" onChange={this.handleChange} />
                        </label>
                    </p>
                    <p>
                        <label>
                            Vorname:<br />
                            <input type="text" name="vorname" onChange={this.handleChange} value={vorname} />
                        </label>
                    </p>
                    <p>
                        <label>
                            Nachname:<br />
                            <input type="text" name="nachname" onChange={this.handleChange} value={nachname} />
                        </label>
                    </p>
                    <p>
                        <label>
                            E-Mail:<br />
                            <input type="text" id="email" name="email" onChange={this.handleChange} value={email}/>
                        </label>
                    </p>
                    <p>
                        <label>
                            Nachricht:<br />
                            <textarea name="nachricht" onChange={this.handleChange} value={nachricht} />
                        </label>
                    </p>
                    <p>
                        <label>
                            Privacy:<br />
                            <input type="text" name="datenschutz" onChange={this.handleChange} value={datenschutz}/>
                        </label>
                    </p>
                    <p>
                    </p>
                    <p>
                        <button type="submit" id={'contact-submit'}>Send</button>
                    </p>
                </form>

                <Form
                    initialValues={{
                        firstNameVal: '',
                        lastNameVal: '',
                        emailVal: '',
                        messageVal: '',
                        privacyVal: false,
                    }}
                    onSubmit={this._handleSubmit2}
                    render={() => (

                        <Form.Children>
                            <Input label="E-Mail*" name="emailVal" inputProps={{type: "email"}}/>

                            <Form.Group widths="2">
                                <Input label="Vorname*" name="firstNameVal"/>
                                <Input label="Nachname*" name="lastNameVal"/>

                            </Form.Group>

                            <TextArea label="Nachricht*" name="messageVal"/>
                            <label className={styles.labelPrivacy}><Link to={'/datenschutz/'}><Icon name={'privacy'}/>Datenschutzhinweise</Link></label>
                            <Checkbox label="zur Kenntnis genommen & akzeptiert*" name="privacyVal">
                            </Checkbox>

                            <Button.Submit className={styles.submitButton}>Absenden</Button.Submit>
                        </Form.Children>
                    )}
                />

            </div>

        )
    }

}
export default ContactReqForm;