import React from "react";
import '../styles/semantic.min.css';
import LayoutContainer from "../container/LayoutContainer";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import PageHeader from "../components/utils/PageHeader";
import SEO from "../components/seo/Seo";
import DesktopContainer from "../container/DesktopContainer";
import {Container} from "semantic-ui-react";
import styles from "../templates/layout.module.css";
import MobileTabletContainer from "../container/MobileTabletContainer";
import ContactReqForm from "../components/supporters/ContactReqForm";
import Office from "../components/about-us/Office";
import ContactDescription from "../components/contact/ContactDescription";


const Kontakt = () => (
    <LayoutContainer>
        <SEO title={'Kontakt'}/>
        <MobileTabletContainer>
            <MainNavigation page={'contact'}/>
            <PageHeader title={'Kontaktanfrage'}/>
            <Container>
                <ContactReqForm/>
                <ContactDescription/>
                <Office/>
            </Container>
            <Footer/>
        </MobileTabletContainer>
        <DesktopContainer>
            <div className={styles.pageWrapper}>
            <MainNavigation page={'contact'}/>
            <PageHeader title={'Kontaktanfrage'}/>
            <Container fluid className={styles.container}>
                <Container>
                    <ContactReqForm/>
                    <ContactDescription/>
                    <Office/>
                </Container>
            </Container>
            <Footer/>
            </div>
        </DesktopContainer>
    </LayoutContainer>
);
export default Kontakt;
